import React, { useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Welcome from './components/Welcome';
import Riders from './components/Riders';
import Agenda from './components/Agenda';
import Evolution from './components/Evolution';
import Menu from './components/Menu';
import Year from './components/Year';
import i18n from './i18n/i18n';

function App() {
  const [selectedSeason, setSelectedSeason] = useState(0);

  const handleSeasonSelect = (season) => {
    setSelectedSeason(season);
  };

  return (
    <Router>
      <div className="container">
        <Year onSelectSeason={handleSeasonSelect} />
        <Header />
        <Menu />
        {getPageContent(selectedSeason)}
      </div>
    </Router>
  );
}

function Header() {
  const location = useLocation();

  // Détermine le titre en fonction de l'URL
  const getTitle = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');

    switch (page) {
      case 'riders':
        return 'Pilotes';
      case 'agenda':
        return 'Calendrier';
      case 'evolution':
        return 'Evolution';
      default:
        return 'Résultats MOTO GP';
    }
  };

  // Met à jour le titre de la page lorsque l'URL change
  React.useEffect(() => {
    document.title = getTitle();
  }, [location]);

  return <h1>{getTitle()}</h1>;
}

function getPageContent(selectedSeason) {
  const params = new URLSearchParams(window.location.search);
  const page = params.get('page');

  switch (page) {
    case 'riders':
      return <Riders season={selectedSeason} />;
    case 'agenda':
      return <Agenda />;
    case 'evolution':
      return <Evolution />;
    default:
      return <Welcome season={selectedSeason} />;
  }
}

export default App;
